<template>
  <span v-if="transaction">
    <span class="small" v-if="showLabel">[Tx </span> <a class="token-id" :href="buildLink" target="_blank">{{ transaction | dotDotDot }}]</a>
  </span>
</template>

<script>
  /* global web3:true */
  import {mapState} from 'vuex';

  export default {
    name: 'clickableTransaction',
    components: {},
    props: {
      transaction: {
        type: String
      },
      showLabel: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapState([
        'etherscanBase',
      ]),
      buildLink: function () {
        return `${this.etherscanBase}/tx/${this.transaction}`;
      }
    }
  };
</script>

<style>

</style>
