<template>
  <div>

    <div class="row mt-3 mb-3">
      <div class="col mx-auto text-center">
        <b-btn variant="outline-primary" class="filter-button m-2"
               @click="activeFilter = 'creations'"
               :disabled="isLoading && activeFilter !== 'creations'"
               :pressed="activeFilter === 'creations'">
          Creations
        </b-btn>
        <b-btn variant="outline-primary" class="filter-button m-2"
               @click="activeFilter = 'sales'"
               :disabled="isLoading && activeFilter !== 'sales'"
               :pressed="activeFilter === 'sales'">
          Sales
        </b-btn>
        <b-btn variant="outline-primary" class="filter-button m-2"
               @click="activeFilter = 'offers'"
               :disabled="isLoading && activeFilter !== 'offers'"
               :pressed="activeFilter === 'offers'">
          Offers
        </b-btn>
        <b-btn variant="outline-primary" class="filter-button m-2"
               @click="activeFilter = 'everything'"
               :disabled="isLoading && activeFilter !== 'everything'"
               :pressed="activeFilter === 'everything'">
          All
        </b-btn>
      </div>
    </div>

    <div class="row" v-if="isLoading && resultsAvailable !== 0">
      <div class="col text-center">
        <loading-spinner class="text-center"></loading-spinner>
      </div>
    </div>

    <div class="row mb-5" v-for="event in activity" :key="event.id">

      <div class="col-sm-6 offset-sm-3 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
        <router-link
          :to="{ name: 'confirmPurchaseSimple', params: { editionNumber: parseInt(event._args._editionNumber) }}">
          <edition-image class="pb-2" :edition="findEdition(parseInt(event._args._editionNumber))"
                         :full="true"/>
        </router-link>
      </div>

      <div class="col-sm-6 offset-sm-3 col-md-6 offset-md-3 col-lg-4 offset-lg-4"
           v-if="artist(event._args._editionNumber)">
        <img :src="artist(event._args._editionNumber).logo" class="artist-avatar" alt="artist-logo"/>
        <router-link
          :to="{ name: 'artist', params: { artistAccount: artist(event._args._editionNumber).ethAddress[0] } }"
          class="artist-link">
          <span class="pl-1 artist-name-lg">{{ artist(event._args._editionNumber).name }}</span>
        </router-link>
<!--        <clickable-address :eth-address="artist(event._args._editionNumber).ethAddress[0]"-->
<!--                           class="small">-->
<!--        </clickable-address>-->
      </div>

      <div class="col-sm-6 offset-sm-3 col-md-6 offset-md-3 col-lg-4 offset-lg-4 pt-2">
        <!-- EventName -->
        <span>
        <router-link
          :to="{ name: 'confirmPurchaseSimple', params: { editionNumber: parseInt(event._args._editionNumber) }}"
          v-if="event._args._editionNumber">
          <code>{{ mapEvent(event)}}</code>
        </router-link>
        <code v-else>{{ mapEvent(event)}}</code>
        </span>

        <!-- TokenID -->
        <span v-if="event._args._tokenId" class="float-right">
          <router-link :to="{ name: 'edition-token', params: { tokenId: event._args._tokenId.toString() }}"
                       class="badge badge-primary">
            {{ event._args._tokenId.toString() }}
          </router-link>
        </span>

        <!-- PriceInWei -->
        <div v-if="event._args._priceInWei && event._args._priceInWei !== '0'" class="pt-2">
          <span><price-in-eth :value="event._args._priceInWei | toEth"></price-in-eth></span>
          <span class="pl-1">
                  <u-s-d-price-converter
                    :price-in-wei="event._args._priceInWei"
                    :usd-exchange-rate="event.exchangeRate.usd">
                </u-s-d-price-converter>
                </span>
        </div>

        <!-- Amount -->
        <div v-else-if="event._args._amount && event._args._amount !== '0'">
          <price-in-eth :value="event._args._amount | toEth"></price-in-eth>
          <u-s-d-price-converter
            :price-in-wei="event._args._amount"
            :usd-exchange-rate="event.exchangeRate.usd">
          </u-s-d-price-converter>
        </div>

      </div>

      <div class="col-sm-6 offset-sm-3 col-md-6 offset-md-3 col-lg-4 offset-lg-4 pt-2">
        <span class="small">{{ event.blockTimestamp | moment('calendar')}}</span>

        <view-transaction-details :transaction="event.transactionHash" class="small float-right"></view-transaction-details>

        <div v-if="event._args._buyer" class="pt-2">
          <span class="text-muted small">Owner: </span>
          <clickable-address :eth-address="event._args._buyer" class="small"></clickable-address>
        </div>

        <div v-if="event._args._to" class="pt-2">
          <span class="text-muted small">Owner: </span>
          <clickable-address :eth-address="event._args._to" class="small"></clickable-address>
        </div>

        <div v-if="event._args._bidder" class="pt-2">
          <span class="text-muted small">Bidder: </span>
          <clickable-address :eth-address="event._args._bidder" class="small"></clickable-address>
        </div>

      </div>

    </div>

    <div class="row" v-if="isLoading">
      <div class="col text-center">
        <loading-spinner class="text-center"></loading-spinner>
      </div>
    </div>

    <infinite-loading @infinite="showMore">
      <div slot="spinner">
      </div>
    </infinite-loading>

    <div class="text-muted small mb-5 mt-3 text-right">
      Updated every 5 minutes
      <br/>
      <small>USD prices powered by <a href="https://www.coingecko.com" target="_blank">coingecko</a></small>
    </div>
  </div>
</template>
<script>

  import * as _ from 'lodash';
  import {mapGetters, mapState} from 'vuex';
  import ClickableAddress from '../components/generic/ClickableAddress';
  import ClickableTransaction from '../components/generic/ClickableTransaction.vue';
  import * as actions from '../store/actions';
  import {PAGES} from '../store/loadingPageState';
  import LoadingSection from '../components/generic/LoadingSection';
  import ViewTransactionDetails from '../components/generic/ViewTransactionDetails';
  import EditionImage from '../components/generic/EditionImage';
  import USDPriceConverter from '../components/generic/USDPriceConverter';
  import PriceInEth from '../components/generic/PriceInEth';
  import {mapEvent, mapMobileEvent} from '../services/eventMapper';
  import InfiniteLoading from 'vue-infinite-loading';
  import LoadingSpinner from '../components/generic/LoadingSpinner';
  import { BButton, BTabs } from 'bootstrap-vue';

  export default {
    name: 'activity',
    components: {
      LoadingSpinner,
      PriceInEth,
      USDPriceConverter,
      EditionImage,
      LoadingSection,
      ClickableAddress,
      ClickableTransaction,
      ViewTransactionDetails,
      InfiniteLoading,
      ...{ BButton, BTabs }
    },
    data() {
      return {
        PAGES,
        activeFilter: 'creations',
        activity: [],
        limit: 10,
        offset: 0,
        isLoading: false,
        resultsAvailable: 0
      };
    },
    computed: {
      ...mapState([
        'eventService'
      ]),
      ...mapGetters('kodaV2', [
        'findEdition'
      ]),
      ...mapGetters([
        'findArtistsForAddress',
      ]),
    },
    methods: {
      mapEvent,
      mapMobileEvent,
      showMore($state) {
        this.isLoading = true;

        this.eventService.loadEventsActivity(this.limit, this.offset, this.activeFilter)
          .then((results) => {
            if (results.success) {
              const {data, limit, offset, resultsAvailable} = results;
              this.limit = limit;
              this.offset = offset + limit;
              this.resultsAvailable = resultsAvailable;
              _.forEach(data, (result) => {
                this.activity.push(result);
              });
              this.$store.dispatch(`kodaV2/${actions.LOAD_EDITIONS}`, _.uniq(_.map(data, '_args._editionNumber')));
              return;
            }
          })
          .then(() => {
            if (this.canShowMore()) {
              $state.complete();
            } else {
              $state.loaded();
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      canShowMore() {
        const totalAvailable = this.resultsAvailable;
        if (totalAvailable === 0) {
          return false;
        }
        return totalAvailable > (this.offset + this.limit);
      },
      artist(editionNumber) {
        const found = this.findEdition(editionNumber);
        if (found) {
          return this.findArtistsForAddress(found.artist.ethAddress);
        }
      },
    },
    watch: {
      activeFilter(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.activity = [];
          this.limit = 10;
          this.offset = 0;
          this.resultsAvailable = 0;
          this.$nextTick(() => {
            this.showMore({loaded: _.noop});
          });
        }
      }
    },
    created() {
      this.$store.watch(
        () => this.eventService.currentNetworkId,
        (networkIfChange) => {
          // if we change networks - restart the process
          if (networkIfChange !== 1) {
            this.activity = [];
            this.limit = 10;
            this.offset = 0;
            this.resultsAvailable = 0;
            this.showMore({loaded: _.noop});
          }
        });
    }
  };
</script>


<style scoped lang="scss">
  @import '../ko-colours.scss';

  .full-banner {
    p {
      margin-bottom: 0;
    }
  }

  /* mobile only */
  @media screen and (max-width: 767px) {
  }

  .img-thumbnail {
    max-width: 500px;
  }

  .filter-button {
    width: 125px;

    /* Attempt to prevent selection of buttons */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  }
</style>
